<template>
	<div class="gallery__slide">
		<transition-group name="fade" tag="div">
			<div
				class="gallery__slide-image"
				:style="{ backgroundImage: 'url(' + image.photo.url + ')' }"
				:key="image.photo.id"
			>
			</div>
		</transition-group>
	</div>
</template>

<script>
export default {
	props: ['image']
}
</script>

<style scoped lang="scss">
.gallery{
	&__slide{
		position: relative;
		min-height: 500px;
		height: 100%;
		&-image{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
}
</style>
