<template>
    <div id="app">
        <the-header/>
        <transition name="fade" mode="out-in">
            <router-view :key="$route.params.id" />
        </transition>
        <cookies />
        <the-footer/>
    </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import Cookies from '@/components/Cookies.vue'
import TheHeader from '@/components/TheHeader.vue'
export default {
    components: {
        'the-header': TheHeader,
        'the-footer': TheFooter,
        'cookies': Cookies
    },
    metaInfo() {
        return {
            titleTemplate: this.seoTitleTemplate,
            title: this.title,
            htmlAttrs: {
                lang: this.$store.getters.getLocale
            },
            meta: [
                { name: 'description', content: this.seoDescription },
                { name: 'robots', content: this.seoRobots },
                { property: 'og:image', content: this.seoSocialImage }
            ],
            link: [
                { rel: 'icon', href: this.optionsFavicon }
            ]
        }
    },
    computed: {
        page() {
            let page = this.$store.getters.getPageByPath(this.$route.path)
            if(page === undefined){
                this.$router.push({name: 'error404'})
            } else {
                return this.$store.getters.getPageByPath(this.$route.path)
            }
        },
        title() {
            if(this.page !== undefined){
                if (this.page.seo_title) {
                    return this.page.seo_title
                }
                else{
                    return this.title
                }
            } else {
                return ''
            }
        },
        seoTitleTemplate() {
            return this.page.seo_title != null ? this.page.seo_title : this.$store.getters.getSeoTitleTemplate
        },
        seoSocialImage() {
            return this.page.seo_social_image != null ? this.page.seo_social_image : 'https://las.com.pl/cms/app/uploads/2020/05/las.png'
        },
        seoDescription() {
            return this.page.seo_description != null ? this.page.seo_description : ''
        },
        seoRobots() {
            return this.page.seo_no_index == true ? 'noindex,nofollow' : 'index,follow'
        },
        optionsFavicon(){
            return this.$store.getters.getOptionsFavicon
        },
		optionsLogo(){
            return this.$store.getters.getOptionsLogo
        }
    }
}
</script>


<style lang="scss" scoped>

</style>
