<template>
	<section
		class="section is-paddingless posts"
	>
		<div class="custom-container">
			<div class="columns is-gapless">
				<div class="column is-4 posts__left-side">
					<div class="posts__left-side--padding">
						<div class="posts__left-inner">
							<h2 class="is-size-5" v-html="news[chosenImage].title"></h2>
							<div class="text is-size-7" v-html="strlen(news[chosenImage].text1)"></div>
							<a
								class="posts__column-button is-size-6"
								v-if="news[chosenImage].button_value && news[chosenImage].button_href"
								:href="news[chosenImage].button_href"
							>{{ news[chosenImage].button_value }}</a>
							<a
								class="posts__column-button is-size-6"
								v-else
								:href="news[chosenImage].link"
								@click.prevent="$router.push(news[chosenImage].link)"
							>{{ news[chosenImage].button_value }}</a>
						</div>
					</div>
				</div>
				<div class="column is-8 posts__relative">
					<gallery-slider :image="news[chosenImage]" />
					<div class="posts__buttons">
						<a
							href="#"
							class="left"
							@click.prevent="prevSlide()"
						><img src="@/assets/las_icons-01.svg" /></a>
						<a
							href="#"
							class="right"
							@click.prevent="nextSlide()"
						><img src="@/assets/las_icons-02.svg" /></a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import gallerySlider from '../GallerySlider.vue'

export default {
	components: {
		gallerySlider
	},
	data() {
		return{
			chosenImage: 0
		}
	},
	methods: {
		strlen(text) {
			if (text.length > 440) {
				text = text.substring(0,440)
				text = text + '...'
			}
			return text
		},
		direct(link) {
			window.location.href = link;
		},
		changeSlide(index){
			this.chosenImage = index
		},
		prevSlide(){
			if (this.chosenImage - 1 < 0) {
				this.chosenImage = this.news.length
			}
			this.chosenImage--
		},
		nextSlide(){
			if (this.chosenImage+1 == this.news.length) {
				this.chosenImage = 0
			}else{
				this.chosenImage++
			}
		}
	},
    computed: {
        news() {
            return this.$store.getters.getNews.slice(0,4)
        },
        postsPage() {
            return this.$store.getters.getOptionsPostsPage
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.posts{
	&__left-inner{
		h2{
			font-family: 'Gilroy-bold';
			color: #000;
			padding-bottom: 20px;
			border-bottom: 1px solid #000;
		}
		.text{
			margin-top: 30px;
		}
	}
	&__left-side{
		background-color: $orange;
		min-height: 500px;
		position: relative;
		&--padding{
            padding: 50px 40px 90px 40px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
			color: black;
		}
	}
	&__relative{
		position: relative;
		min-height: 500px;
	}
	&__buttons{
		.left, .right{
			background-color: $yellow;
			width: 30px;
			height: 40px;
			z-index: 800;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
            z-index: 1;
		}
		.left{
			left: 0;
			img{
				margin-top: 4px;
			}
		}
		.right{
			right: 0;
			img{
				margin-top: 4px;
			}
		}
	}
	&__column-button{
		border: 1px solid black;
		width: calc(100% - 80px);
		text-align: center;
		color: black;
		text-transform: uppercase;
		display: block;
		padding: 10px 0;
		position: absolute;
		bottom: 30px;
		transition: .2s ease;
		&:hover{
			background-color: black;
			color: white;
		}
	}
}
</style>
