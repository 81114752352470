<template>
	<section class="section is-paddingless baner">
		<div class="custom-container">
			<a
				href="https://booking.las.com.pl/pl/booking"
				target="_blank"
				v-if="data.baner.url.includes('rezerwacja') && locale === 'pl'"
			>
				<figure class="image baner__maxheight">
					<img
						:src="data.baner.url"
						:alt="data.baner.alt"
					>
				</figure>
			</a>
            <a
				href="https://booking.las.com.pl/en/booking"
				target="_blank"
				v-else-if="data.baner.url.includes('rezerwacja') && locale === 'en'"
			>
				<figure class="image baner__maxheight">
					<img
						:src="data.baner.url"
						:alt="data.baner.alt"
					>
				</figure>
			</a>
            <a
				href="https://booking.las.com.pl/ua/booking"
				target="_blank"
				v-else-if="data.baner.url.includes('rezerwacja') && locale === 'ua'"
			>
				<figure class="image baner__maxheight">
					<img
						:src="data.baner.url"
						:alt="data.baner.alt"
					>
				</figure>
			</a>
            <div v-else>
                <figure class="image baner__maxheight">
                    <img
                        :src="data.baner.url"
                        :alt="data.baner.alt"
                    >
                </figure>
            </div>
		</div>
	</section>
</template>

<script>
export default {
    data() {
        return{}
    },
    computed: {
        locale(){
            return this.$store.getters.getLocale
        }
    }
}
</script>


<style lang="scss">
@import "@/styles/framework/variables.sass";
.baner__maxheight{
	max-height: 500px;
	overflow: hidden;
}
</style>
