<template>
    <div>
        <flexible-content/>
        <section class="section is-paddingless custom-container" v-if="page && page.content">
			<div class="columns">
				<div class="column is-12">
					<figure class="image post__maxheight">
						<img
							:src="page.photo.url"
							alt="Akademiki LAS"
						>
					</figure>
				</div>
			</div>
        </section>
		<section class="section is-paddingless post custom-container" v-if="page && page.content">
			<div class="columns">
				<div class="column is-12 post__content">
					<h1 class="is-size-3">{{ page.title }}</h1>
                    <div
						class="is-size-6"
						v-html="page.text1"
					></div>
                    <div
						class="is-size-6"
						v-html="page.text2"
					></div>
					<div
						class="is-size-6"
						v-html="page.content"
					></div>
				</div>
			</div>
		</section>
    </div>
</template>

<script>
import FlexibleContent from '@/components/FlexibleContent.vue'
export default {
    components: {
        'flexible-content': FlexibleContent
    },
    computed: {
        page() {
            return this.$store.getters.getPageByPath(this.$route.path)
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.post{
	background-color: white;
    /deep/p, /deep/li{
        margin: 20px 0;
    }
    /deep/ul{
        list-style: circle !important;
        margin-left: 15px;
    }
	&__maxheight{
		max-height: 500px;
		overflow: hidden;
	}
	&__content{
		padding: 50px;
		color: black;
		h1{
			font-family: 'Gilroy-bold';
			border-bottom: 1px solid black;
			padding-bottom: 12px;
		}
		div{
			margin-top: 20px;
		}
	}
}
</style>
