<template>
	<section class="section is-paddingless gallery">
		<div class="custom-container">
			<div class="columns is-gapless">
				<div class="column is-4 gallery__left-side">
					<div class="gallery__left-side--padding">
						<div class="gallery__left-inner">
							<h2 v-html="data.name" class="is-size-3"></h2>
							<div class="text is-size-6" v-html="data.gallery[chosenImage].content">

							</div>
						</div>
						<div class="gallery__numbers">
							<a
								href="#"
								@click.prevent="changeSlide(index)"
								v-for="(item, index) in data.gallery"
								:key="index"
								:class="numberColor(index)"
								v-html="index+1"
							></a>
						</div>
					</div>
				</div>
				<div class="column is-8 gallery__relative">
					<gallery-slider :image="data.gallery[chosenImage]" />
					<div class="gallery__buttons">
						<a
							href="#"
							class="left"
							@click.prevent="prevSlide()"
						><img src="@/assets/las_icons-01.svg" /></a>
						<a
							href="#"
							class="right"
							@click.prevent="nextSlide()"
						><img src="@/assets/las_icons-02.svg" /></a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import GallerySlider from '../GallerySlider.vue'

export default{
	components: {
		GallerySlider
	},
	data() {
		return{
			chosenImage: 0
		}
	},
	methods: {
		numberColor(index){
			if (this.chosenImage == index){ return 'colorize' }
		},
		changeSlide(index){
			this.chosenImage = index
		},
		prevSlide(){
			if (this.chosenImage - 1 < 0) {
				this.chosenImage = this.data.gallery.length
			}
			this.chosenImage--
		},
		nextSlide(){
			if (this.chosenImage+1 == this.data.gallery.length) {
				this.chosenImage = 0
			}else{
				this.chosenImage++
			}
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.gallery{
	&__left-inner{
		h2{
			font-family: 'Gilroy-bold';
			color: #000;
			padding-bottom: 20px;
			border-bottom: 1px solid #000;
		}
		.text{
			margin-top: 30px;
		}
	}
	&__left-side{
		background-color: white;
		min-height: 500px;
		position: relative;
		&--padding{
            padding: 50px 40px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
		}
	}
	&__relative{
		position: relative;
		min-height: 500px;
	}
	&__buttons{
		.left, .right{
			background-color: $yellow;
			width: 30px;
			height: 40px;
			z-index: 800;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
            z-index: 1;
		}
		.left{
			left: 0;
			img{
				margin-top: 4px;
			}
		}
		.right{
			right: 0;
			img{
				margin-top: 4px;
			}
		}
	}
	&__numbers{
        display: flex;
        bottom: 50px;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
		.colorize{
			background-color: $yellow
		}
		a{
			display: block;
			border: 1px solid black;
			width: 40px;
			font-family: 'Gilroy-bold';
			color: black;
			padding-top: 8px;
			margin: 0 10px 10px 0;
			height: 40px;
			text-align: center;
		}
	}
}
</style>
