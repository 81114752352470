<template>
    <section class="section is-paddingless">
		<div class="custom-container">
			<div class="columns is-gapless tiles">
				<div
					class="column is-size-4 tiles__tile"
					v-for="item in data.column"
					:key="item.id"
					:style="{backgroundColor: item.color}"
                    :class="[item.custom_class]"
				>
					<figure v-if="item.photo.url" class="image">
						<img :src="item.photo.url" alt="">
					</figure>
					<div class="tiles__tile__content">
						<h2 v-if="item.title" class="is-size-4 is-size-6-touch">{{ item.title }}</h2>
						<div class="tiles__text-editor is-size-7" v-html="item.description"></div>
						<a
							v-if="item.button_value && !item.href.includes('http')"
							:href="item.href"
							@click.prevent="$router.push(item.href)"
							class="tiles__tile__content-column-button is-size-6"
                        >{{ item.button_value }}</a>
                        <a
                            v-else-if="item.button_value"
                            :href="item.href"
                            target="_blank"
                            class="tiles__tile__content-column-button is-size-6"
                        >{{ item.button_value }}</a>
					</div>
				</div>
			</div>
		</div>
    </section>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.tiles{
	&__tile{
		position: relative;
		min-height: 605px;
        @include touch {
            min-height: 0;
        }
		&__content{
			padding: 50px;
            color: black;
            @include mobile{
    			padding-bottom: 130px;
    		}
			h2{
				color: black;
				margin-bottom: 20px;
				font-family: 'Gilroy-Bold';
                font-weight: bold;
                line-height: 1.2;
			}
			p{
				color: black;
			}
			&-column-button{
				border: 1px solid black;
				width: calc(100% - 100px);
				text-align: center;
				color: black;
				text-transform: uppercase;
				display: block;
				padding: 13px 0;
				position: absolute;
				bottom: 40px;
				transition: .2s ease;
				&:hover{
					background-color: black;
					color: white;
				}
			}
		}
	}
    /deep/ &__text-editor {
        & p {
            &:not(:last-child){
                margin-bottom: 1rem;
            }
        }
        & strong {
            color: black;
        }
        & h3 {
            font-size: 1rem;
            font-weight: bold;
            color: black;
            padding-top: 15px;
            margin-bottom: 15px;
            border-top: 1px solid black;
            display: flex;
            & img {
                width: 20px;
                margin-right: 10px;
            }
        }
        & h4 {
            margin-bottom: 1rem;
            font-size: 1rem;
        }
        & h5 {
            margin-bottom: 1rem;
        }
        & li {
            font-size: 1rem;
            line-height: 41px;
            font-weight: bold;
            color: black;
            display: flex;
            padding: 2px 0;
            & img {
                width: 20px;
                margin-right: 10px;
            }
        }
    }
    & .border-right {
        border-right: 1px solid black;
        @include touch {
            border-right: none;
        }
    }
    & /deep/.book-now {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        & h2,
        & h4 {
            font-weight: bold;
            margin: 0 auto;
        }
        & h2 {
            font-size: 64px;
        }
        & h4 {
            font-size: 32px;
            text-transform: uppercase;
        }
    }
}

/deep/.check{
    outline: none;
    background-color: $yellow;
    border: 0;
    color: black;
    font-size: 1.4rem;
    padding: 20px 0;
    width: 100%;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    font-family: 'Gilroy-Bold';
}
</style>
