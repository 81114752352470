<template>
    <section class="section instagram">
        <h1><a :href="instapage">@{{ username }} on instagram</a></h1>
        <div v-if="grams.length > 0">
            <div v-for="(gram, index) in grams" :key="index">
            <a :href="gram.link">
                <img :src="gram.images.standard_resolution.url" :alt="gram.text" />
            </a>
            </div>
        </div>
        <div v-else class="loading"></div>
        <div v-if="error" class="error">Sorry, the Instagrams couldn't be fetched.</div>
        <button @click="getMoreGrams">Load More</button>
    </section>
</template>

<script>
export default {
    name: "InstagramComponent",
    data() {
        return {
            access_token: "your access token here",
            url: "https://api.instagram.com/v1/users/self/media/recent/",
            username: "",
            grams: [],
            next_url: "",
            error: false
        }
    },
    computed: {
        instapage() {
            return 'https://www.instagram.com/' + this.username
        }
    },
    methods: {
        getGrams() {
            axios.get(this.url + "?access_token=" + this.access_token)
                .then(({data}) => {
                    this.grams = data.data
                    this.username = data.data[0].user.username
                    this.next_url = data.pagination.next_url
                })
                .catch(function (error) {
                    console.log(error)
                    this.error = true
                });
        },
        getMoreGrams(){
            axios.get(this.next_url)
                .then(({data}) => {
                    this.grams = this.grams.concat(data.data)
                    this.next_url = data.pagination.next_url
                })
                .catch(function (error) {
                    console.log(error)
                    this.error = true
                });
        }
    },
    created() {
        this.getGrams();
    }
}

</script>

<style scoped lang="scss">

</style>
