<template>
    <section class="section is-paddingless contact">
		<div
			class="modal"
			v-if="modalOpen"
		>
			<div class="content" v-html="textModal"></div>
			<img
				src="@/assets/X_ZAMKNIECIE.svg"
				@click="modalOpen = !modalOpen"
			>
		</div>
		<div class="custom-container">
			<div class="columns is-gapless">
				<div class="column is-4">
					<div class="columns is-gapless contact__flex">
						<div class="column is-12 contact__left">
							<div class="contact__padding">
								<h2 class="is-size-4">{{ data.first_column_title }}</h2>
								<p>
									<ul>
										<li>Operator akademików Living and Studying - biuro</li>
										<li>Rynek 7, 50-106 Wrocław</li>
										<li>Tel: +48 662 266 538</li>
										<li>Email: rosnie@las.com.pl</li>
									</ul>
								</p>
							</div>
						</div>
						<div class="column is-12 contact__left contact__left--yellow">
							<div class="contact__padding">
								<h2 class="contact__faq">FAQ</h2>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-4 contact__newsletter">
					<div class="contact__padding">
                        <h2 class="is-size-4">{{ data.second_column_title }}</h2>
						<p class="is-size-7" v-html="data.second_column_description"></p>
						<form @submit.prevent="onSubmit">
                            <vue-recaptcha
	                            ref="recaptcha"
	                            @verify="onVerify"
	                            @expired="onExpired"
	                            size="invisible"
	                            :sitekey="sitekey"
	                        >
	                        </vue-recaptcha>
							<input
								type="email"
								v-model="email"
								class="contact__input contact__input--email"
								placeholder="EMAIL"
								required
							>
							<div class="contact__input-group">
								<input
									id="commercial"
									type="checkbox"
									required
								>
								<label for="commercial" class="is-size-7" @click="openModal(data.personal_data)"> {{ data.personal_text }}</label>
							</div>
							<div class="contact__input-group">
								<input
									id="communication"
									type="checkbox"
									required
								>
								<label for="communication" class="is-size-7" @click="openModal(data.commercial_information)"> {{ data.commercial_text }}</label>
							</div>
							<div class="contact__input-group">
								<input
									id="personal"
									type="checkbox"
									required
								>
								<label for="personal" class="is-size-7" @click="openModal(data.terms_and_condition)"> {{ data.terms_text }}</label>
							</div>
							<div class="contact__input-group" v-if="data.electronic_communication">
								<input
									id="terms"
									type="checkbox"
									required
								>
								<label for="terms" class="is-size-7" @click="openModal(data.electronic_communication)"> {{ data.electronic_text }}</label>
							</div>
                            <div
                                v-if="infoBox.type != 0"
                            >
                                <div
                                    class="tiles__messagebody"
                                    :class="{'success':infoBox.type == 1,'danger':infoBox.type == 2}"
                                >
                                    {{ infoBox.content }}
                                </div>
                            </div>
							<input
								class="contact__column-button is-size-6"
								type="submit"
								name="submit"
								:value="sendLabel"
                                @click="$gtm.push({ event: 'conversion', send_to:'AW-850056327/noeCCO6Uy5kBEIepq5UD' })"
							>
						</form>
					</div>
				</div>
                <message :messagedata="data"></message>
			</div>
		</div>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
import Message from '../Message.vue'

export default{
	components: {
		Message,
        'vue-recaptcha': VueRecaptcha
	},
	data() {
		return{
			name: '',
            email: '',
            message: '',
            sendLabel:  'Wyślij',
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ''
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			modalOpen: false,
            textModal: '',
		}
	},
	methods: {
        openModal(text) {
            this.modalOpen = true;
            this.textModal = text;
        },
		onSubmit(){
            this.$refs.recaptcha.execute()
            this.sendLabel = 'Sending...'
        },
        onVerify(recaptchaToken){
            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set('email', this.email);
            bodyFormData.set('recaptchaToken', recaptchaToken);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axios.post( process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config).then((response) => {
                if(response.data == 'ok'){
                    this.infoBox.type = 1
                    this.infoBox.content = 'Email was send successfully.'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },3000)
                } else {
                    this.infoBox.type = 2
                    this.infoBox.content = 'Server side error'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },3000)
                }

            }).catch((err) => {
                this.infoBox.type = 2
                this.infoBox.content = 'Cannot send email, please try again later.'
                this.sendLabel = 'Send'
                setTimeout(() => {
                    this.infoBox.type = 0
                },3000)
                console.log(getErrorMessage(err));
                //helper to get a displayable message to the user
                function getErrorMessage(err) {
                    let responseBody;
                    responseBody = err.response;
                    if (!responseBody) {
                        responseBody = err;
                    } else {
                        responseBody = err.response.data || responseBody;
                    }
                    return responseBody.message || JSON.stringify(responseBody);
                }
            })
        },
        onExpired(){
            console.log('Expired')
        }
	},
	computed: {
        locale(){
            return this.$store.getters.getLocale
        },
	},
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.modal{
	background-color: black;
	color: white;
	z-index: 999;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	max-width: 800px;
	width: 100%;
	padding: 30px;
	display: block;
	min-height: 300px;
    overflow: scroll;
	img{
		position: absolute;
		right: 20px;
		top: 20px;
		filter: invert(1);
		cursor: pointer;
	}
}
.contact{
    &__column-button{
        border: 1px solid black;
        width: calc(100% - 100px);
        text-align: center;
        color: black;
        text-transform: uppercase;
        display: block;
        padding: 13px 0;
        position: absolute;
        bottom: 40px;
        transition: .2s ease;
        cursor: pointer;
        background-color: transparent;
        &:hover{
            background-color: black;
            color: white;
        }
    }
    &__messagebody{
        margin-top: 20px;
        & .success{
            color: green;
        }
        & .danger{
            color: red;
        }
    }
	&__newsletter{
		background-color: #FF6A3B;
		padding: 50px;
		min-height: 550px;
		position: relative;
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__flex{
		flex-direction: column;
	}
	&__left{
		background-color: white;
		padding: 50px;
		min-height: 275px;
		position: relative;
		&--yellow{
			background-color: #FFD141;
		}
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__message{
		background-color: #FFD141;
		padding: 50px;
		position: relative;
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
		.contact__padding{padding: 50px 50px 120px 50px;}
	}
	&__padding{
		padding: 50px;
		p{
			color: black;
		}
	}
	&__faq{
		color: black;
		font-family: 'Gilroy-bold';
		font-size: 8rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
	&__input{
		width: 100%;
		background: transparent;
		border: none;
		border-bottom: 1px solid black;
		padding: 20px 0;
		color: black;
		&--email{
			margin-bottom: 30px;
		}
		&-group{
			label{
				color: black;
				cursor: pointer;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		&::placeholder{
			color: black;
		}
	}
}
</style>
