<template>
    <section class="faq section custom-container">
        <h2 class="heading-secondary">{{ data.title }}</h2>
        <div class="faq__questions-list columns">
            <div class="faq__single-question column is-12" v-for="(singleQuestion, index) in data.questions" :key="index">
                <h3 class="faq__question">{{ singleQuestion.question }}</h3>
                <div v-html="singleQuestion.answer" class="faq__answer"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/addons.sass";

.faq {
    background-color: white;
    padding: 50px;
    font: 400 14px/1.4 'Roboto Mono', monospace;

    &__questions-list {
        flex-direction: column;
    }


    &__single-question {
        margin-bottom: 20px;

        @include las-touch {
            margin-bottom: 0px;
        }
    }

    &__question {
        font-size: 16px;
        line-height: 24px;
        margin: 15px 0px;
        border-bottom: 1px solid #000;
        font-family: GilroyBold, sans-serif;
        padding-bottom: 15px;
        font-weight: 900;
        color: #000;
    }

    &__answer {

    }
}

</style>

