<template>
    <section class="section is-paddingless">
		<div class="custom-container">
            <div
    			class="modal"
    			v-if="modalOpen"
    		>
    			<div class="content is-size-6" v-html="modalText"></div>
    			<img
    				src="@/assets/X_ZAMKNIECIE.svg"
    				@click="modalOpen = !modalOpen"
    			>
    		</div>
			<div class="columns is-gapless tiles">
				<div
					class="column is-size-4 tiles__tile"
					v-for="item in data.column"
					:key="item.id"
					:style="{backgroundColor: item.color}"
                    :class="[item.custom_class]"
				>
					<figure v-if="item.photo.url" class="image">
						<img :src="item.photo.url" alt="">
					</figure>
					<div class="tiles__tile__content">
						<h2 v-if="item.title" class="is-size-4 is-size-6-touch">{{ item.title }}</h2>
						<div class="tiles__text-editor is-size-7" v-html="item.description"></div>
						<a
							v-if="item.button_value && !item.href.includes('http')"
							:href="item.href"
							@click.prevent="$router.push(item.href)"
							class="tiles__tile__content-column-button is-size-6"
                        >{{ item.button_value }}</a>
                        <a
                            v-else-if="item.button_value"
                            :href="item.href"
                            target="_blank"
                            class="tiles__tile__content-column-button is-size-6"
                        >{{ item.button_value }}</a>
					</div>
				</div>
                <div class="column is-4 contact__newsletter">
					<div class="contact__padding">
						<h2 class="is-size-4">{{ data.newsletter_title }}</h2>
						<p v-html="data.description"></p>
						<form @submit.prevent="onSubmit">
                            <vue-recaptcha
	                            ref="recaptcha"
	                            @verify="onVerify"
	                            @expired="onExpired"
	                            size="invisible"
	                            :sitekey="sitekey"
	                        >
	                        </vue-recaptcha>
							<input
								type="email"
								v-model="email"
								class="contact__input contact__input--email"
								placeholder="EMAIL"
								required
							>
							<div class="contact__input-group">
								<input
									id="commercial"
									type="checkbox"
									required
								>
								<label for="commercial" class="is-size-7" @click="openModal(data.checkbox1_full_info)"> {{ data.checkbox1_text }}</label>
							</div>
							<div class="contact__input-group">
								<input
									id="communication"
									type="checkbox"
									required
								>
								<label for="communication" class="is-size-7" @click="openModal(data.checkbox2_full_info)"> {{ data.checkbox2_text }}</label>
							</div>
							<div class="contact__input-group">
								<input
									id="personal"
									type="checkbox"
									required
								>
								<label for="personal" class="is-size-7" @click="openModal(data.checkbox3_full_info)"> {{ data.checkbox3 }}</label>
							</div>
							<div class="contact__input-group">
								<input
									id="terms"
									type="checkbox"
									required
								>
								<label for="terms" class="is-size-7" @click="openModal(data.checkbox4_full_info)"> {{ data.checkbox4_text }}</label>
							</div>
                            <div
                                v-if="infoBox.type != 0"
                            >
                                <div
                                    class="tiles__messagebody"
                                    :class="{'success':infoBox.type == 1,'danger':infoBox.type == 2}"
                                >
                                    {{ infoBox.content }}
                                </div>
                            </div>
							<input
								class="contact__column-button is-size-6"
								type="submit"
								name="submit"
								:value="sendLabel"
							>

						</form>
					</div>
				</div>
			</div>
		</div>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'

export default {
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    data() {
        return {
            modaltext2: '',
            modaltext3: '',
            modaltext4: '',
            modalOpen: false,
            modalText: '',
            email: '',
            sendLabel:  this.data.button_value,
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ''
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        }
    },
    methods: {
        openModal(text) {
            this.modalOpen = true
            this.modalText = text
        },
        onSubmit(){
            this.$refs.recaptcha.execute()
            this.sendLabel = 'Sending...'
        },
        onVerify(recaptchaToken){
            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set('email', this.email);
            bodyFormData.set('recaptchaToken', recaptchaToken);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axios.post( process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config).then((response) => {
                if(response.data == 'ok'){
                    this.infoBox.type = 1
                    this.infoBox.content = 'Email was send successfully.'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },3000)
                } else {
                    this.infoBox.type = 2
                    this.infoBox.content = 'Server side error'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },3000)
                }

            }).catch((err) => {
                this.infoBox.type = 2
                this.infoBox.content = 'Cannot send email, please try again later.'
                this.sendLabel = 'Send'
                setTimeout(() => {
                    this.infoBox.type = 0
                },3000)
                console.log(getErrorMessage(err));
                //helper to get a displayable message to the user
                function getErrorMessage(err) {
                    let responseBody;
                    responseBody = err.response;
                    if (!responseBody) {
                        responseBody = err;
                    } else {
                        responseBody = err.response.data || responseBody;
                    }
                    return responseBody.message || JSON.stringify(responseBody);
                }
            })
        },
        onExpired(){
            console.log('Expired')
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.modal{
	background-color: black;
	color: white;
	z-index: 999;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	max-width: 800px;
	width: 100%;
	padding: 30px;
	display: block;
	min-height: 300px;
    overflow: scroll;
	img{
		position: absolute;
		right: 20px;
		top: 20px;
		filter: invert(1);
		cursor: pointer;
	}
}

.tiles{
    &__messagebody{
        margin-top: 20px;
        & .success{
            color: green;
        }
        & .danger{
            color: red;
        }
    }
	&__tile{
		position: relative;
		min-height: 605px;
        @include touch {
            min-height: 0;
        }
		&__content{
			padding: 50px;
            color: black;
            @include mobile{
    			padding-bottom: 130px;
    		}
			h2{
				color: black;
				margin-bottom: 20px;
				font-family: 'Gilroy-Bold';
                font-weight: bold;
                line-height: 1.2;
			}
			p{
				color: black;
			}
            /deep/ul li{
                font-size: .7rem;
            }
			&-column-button{
				border: 1px solid black;
				width: calc(100% - 100px);
				text-align: center;
				color: black;
				text-transform: uppercase;
				display: block;
				padding: 13px 0;
				position: absolute;
				bottom: 40px;
				transition: .2s ease;
				&:hover{
					background-color: black;
					color: white;
				}
			}
		}
	}
    /deep/ &__text-editor {
        & p {
            &:not(:last-child){
                margin-bottom: 1rem;
            }
        }
        & strong {
            color: black;
        }
        & h3 {
            font-size: 1rem;
            font-weight: bold;
            color: black;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid black;
            display: flex;
            & img {
                width: 20px;
                margin-right: 10px;
            }
        }
        & h4 {
            margin-bottom: 1rem;
            font-size: 1rem;
        }
        & h5 {
            margin-bottom: 1rem;
        }
        & li {
            font-size: 1rem;
            font-weight: bold;
            color: black;
            display: flex;
            padding: 2px 0;
            & img {
                width: 20px;
                margin-right: 10px;
            }
        }
    }
    & .border-right {
        border-right: 1px solid black;
        @include touch {
            border-right: none;
        }
    }
    & /deep/.book-now {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        & h2,
        & h4 {
            font-weight: bold;
            margin: 0 auto;
        }
        & h2 {
            font-size: 64px;
        }
        & h4 {
            font-size: 32px;
            text-transform: uppercase;
        }
    }
}

.contact{
    &__column-button{
        border: 1px solid black;
        width: calc(100% - 100px);
        text-align: center;
        color: black;
        text-transform: uppercase;
        display: block;
        padding: 13px 0;
        position: absolute;
        bottom: 40px;
        transition: .2s ease;
        cursor: pointer;
        background-color: transparent;
        &:hover{
            background-color: black;
            color: white;
        }
    }
    &__messagebody{
        margin-top: 20px;
        & .success{
            color: green;
        }
        & .danger{
            color: red;
        }
    }
	&__newsletter{
		background-color: #FED141;
		padding: 50px;
		min-height: 550px;
		position: relative;
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__flex{
		flex-direction: column;
	}
	&__left{
		background-color: white;
		padding: 50px;
		min-height: 275px;
		position: relative;
		&--yellow{
			background-color: #FFD141;
		}
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__message{
		background-color: #FFD141;
		padding: 50px;
		position: relative;
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__padding{
		padding: 50px;
		p{
			color: black;
		}
	}
	&__faq{
		color: black;
		font-family: 'Gilroy-bold';
		font-size: 8rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
	&__input{
		width: 100%;
		background: transparent;
		border: none;
		border-bottom: 1px solid black;
		padding: 20px 0;
		color: black;
		&--email{
			margin-bottom: 30px;
		}
		&-group{
			label{
				color: black;
				cursor: pointer;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		&::placeholder{
			color: black;
		}
	}
}
</style>
