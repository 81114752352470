<template>
    <div class="column is-4 contact__message">
        <div
			class="modal"
			v-if="modalOpen"
		>
			<div class="content" v-html="textModal"></div>
			<img
				src="@/assets/X_ZAMKNIECIE.svg"
				@click="modalOpen = !modalOpen"
			>
		</div>
        <div class="contact__padding contact__padding2">
            <h2 class="is-size-4">{{ messagedata.third_column_title }}</h2>
            <form @submit.prevent="onSubmit">
                <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    size="invisible"
                    :sitekey="sitekey"
                >
                </vue-recaptcha>

                <input
                    type="text"
                    class="contact__input"
                    v-model="name"
                    :placeholder="messagedata.name_placeholder"
                    required
                >
                <input
                    type="email"
                    class="contact__input"
                    v-model="email"
                    :placeholder="messagedata.email_placeholder"
                    required
                >
                <input
                    type="tel"
                    class="contact__input"
                    v-model="phone"
                    :placeholder="messagedata.phone_placeholder"
                    required
                >
                <textarea
                    class="contact__input"
                    v-model="message"
                    :placeholder="messagedata.message_placeholder"
                    required
                ></textarea>
                <div class="contact__input-group">
                    <input
                        id="terms2"
                        type="checkbox"
                        required
                    >
                    <label for="terms2" class="is-size-7" @click="openModal(messagedata.personal_data)"> {{ messagedata.personal_text }}</label>
                </div>
                <div
                    v-if="infoBox.type != 0"
                >
                    <div
                        class="tiles__messagebody"
                        :class="{'success':infoBox.type == 1,'danger':infoBox.type == 2}"
                    >
                        {{ infoBox.content }}
                    </div>
                </div>
                <input
                    class="contact__column-button is-size-6"
                    type="submit"
                    name="submit"
                    :value="sendLabel"
                >
            </form>
        </div>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'

export default {
	components: {
        'vue-recaptcha': VueRecaptcha
    },
    props: {
        messagedata: Object
    },
	data() {
		return{
			name: '',
            email: '',
            message: '',
            phone: '',
            sendLabel:  'Wyślij',
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ''
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			modalOpen: false,
            textModal: '',
		}
	},
	methods: {
        openModal(text) {
            this.modalOpen = true;
            this.textModal = text;
        },
		onSubmit(){
            this.$refs.recaptcha.execute()
            this.sendLabel = 'Sending...'
        },
        onVerify(recaptchaToken){
            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set('email', this.email);
            bodyFormData.set('name', this.name);
            bodyFormData.set('message', this.message);
            bodyFormData.set('phone', this.phone);
            bodyFormData.set('recaptchaToken', recaptchaToken);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axios.post( process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config).then((response) => {
                if(response.data == 'ok'){
                    this.infoBox.type = 1
                    this.infoBox.content = 'Email was send successfully.'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },3000)
                } else {
                    this.infoBox.type = 2
                    this.infoBox.content = 'Server side error'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },3000)
                }

            }).catch((err) => {
                this.infoBox.type = 2
                this.infoBox.content = 'Cannot send email, please try again later.'
                this.sendLabel = 'Send'
                setTimeout(() => {
                    this.infoBox.type = 0
                },3000)
                console.log(getErrorMessage(err));
                //helper to get a displayable message to the user
                function getErrorMessage(err) {
                    let responseBody;
                    responseBody = err.response;
                    if (!responseBody) {
                        responseBody = err;
                    } else {
                        responseBody = err.response.data || responseBody;
                    }
                    return responseBody.message || JSON.stringify(responseBody);
                }
            })
        },
        onExpired(){
            console.log('Expired')
        }
	},
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.modal{
	background-color: black;
	color: white;
	z-index: 999;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	max-width: 800px;
	width: 100%;
	padding: 30px;
	display: block;
	min-height: 300px;
    overflow: scroll;
	img{
		position: absolute;
		right: 20px;
		top: 20px;
		filter: invert(1);
		cursor: pointer;
	}
}

.contact{
    &__column-button{
        border: 1px solid black;
        width: calc(100% - 100px);
        text-align: center;
        color: black;
        text-transform: uppercase;
        display: block;
        padding: 13px 0;
        position: absolute;
        bottom: 40px;
        transition: .2s ease;
        cursor: pointer;
        background-color: transparent;
        &:hover{
            background-color: black;
            color: white;
        }
    }
    &__messagebody{
        margin-top: 20px;
        & .success{
            color: green;
        }
        & .danger{
            color: red;
        }
    }
	&__newsletter{
		background-color: #FF6A3B;
		padding: 50px;
		min-height: 550px;
		position: relative;
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__flex{
		flex-direction: column;
	}
	&__left{
		background-color: white;
		padding: 50px;
		min-height: 275px;
		position: relative;
		&--yellow{
			background-color: #FFD141;
		}
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__message{
		background-color: #FFD141;
		padding: 50px;
		position: relative;
		h2{
			font-family: 'Gilroy-bold';
			color: black;
			margin-bottom: 20px;
		}
	}
	&__padding{
		padding: 50px;
		p{
			color: black;
		}
	}
    &__padding2{padding: 50px 50px 120px 50px}

	&__faq{
		color: black;
		font-family: 'Gilroy-bold';
		font-size: 8rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
	&__input{
		width: 100%;
		background: transparent;
		border: none;
		border-bottom: 1px solid black;
		padding: 20px 0;
		color: black;
		&--email{
			margin-bottom: 30px;
		}
		&-group{
			label{
				color: black;
				cursor: pointer;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		&::placeholder{
			color: black;
		}
	}
}
</style>
