<template>
	<section
		class="section is-paddingless post"
	>
		<div class="custom-container">
			<div
				v-for="(item,key) in news"
				class="columns is-marginless post__item"
				:key="key"
				:class="[ item.revert.includes('revert') ? 'post__revert' : '' ]"
				:style="{ backgroundColor: item.color }"
			>
				<div
					class="column is-8 post__photo-large"
					v-if="item.text2 === null"
					:style="{ backgroundImage: 'url(' + item.photo.url + ')' }"
				>
				</div>
				<div
					class="column is-8"
					v-else
				>
					<div class="columns">
						<div
							class="column is-6 post__content post__border"
						>
							<h2 class="is-size-4">{{ item.title }}</h2>
							<div class="is-size-7 post__margin" v-html="strlen(item.text1)"></div>
						</div>
						<div class="column is-6 post__content is-size-7">
							<div v-html="strlen(item.text2)"></div>
							<a
								class="post__column-button is-size-6"
								v-if="item.button_value"
								:href="item.button_href ? item.button_href : item.link"
								@click.prevent="item.button_href ? direct(item.button_href) : $router.push(item.link)"
							>{{ item.button_value }}</a>
						</div>
					</div>
				</div>
				<div
					class="column is-4"
					v-if="item.text2 === null"
				>
					<div class="post__content">
						<h2 class="is-size-3">{{ item.title }}</h2>
						<div
							v-html="strlen(item.text2)"
							class="is-size-7"
						></div>
						<a
							class="post__column-button is-size-6"
							v-if="item.button_value"
							:href="item.button_href ? item.button_href : item.link"
							@click.prevent="item.button_href ? direct(item.button_href) : $router.push(item.link)"
						>{{ item.button_value }}</a>
					</div>
				</div>
				<div
					class="column is-4 post__photo"
					:style="{ backgroundImage: 'url(' + item.photo.url + ')' }"
					v-else
				>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	methods: {
		direct(link) {
			window.location.href = link;
		},
		strlen(text) {
			if (text.length > 440) {
				text = text.substring(0,440)
				text = text + '...'
			}
			return text
		}
	},
    computed: {
        news() {
            return this.$store.getters.getNews.slice(0,20)
        },
        postsPage() {
            return this.$store.getters.getOptionsPostsPage
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.post{
	&__revert{
		flex-direction: row-reverse;
	}
	&__item{
		min-height: 500px;
	}
	&__photo-large, &__photo{
		min-height: 500px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	&__content{
		color: #000;
		padding: 0 50px;
		margin-top: 50px;
		min-height: 400px;
		position: relative;
		&:last-child{
			@include mobile{
				padding-bottom: 100px;
			}
		}
		@include mobile{
			min-height: 200px;
		}
		h2{
			font-family: 'Gilroy-Bold';
			font-weight: 600;
			padding-bottom: 15px;
			border-bottom: 1px solid #000;
            line-height: 1.2;
		}
        /deep/ p {
            margin-bottom: 1rem;
        }
	}
	&__margin{
		margin-top: 20px;
	}
	&__border{
		border-right: 1px solid #000;
	}
	&__column-button{
		border: 1px solid black;
		width: calc(100% - 100px);
		text-align: center;
		color: black;
		text-transform: uppercase;
		display: block;
		padding: 10px 0;
		position: absolute;
		bottom: 0;
		transition: .2s ease;
		@include mobile{
			bottom: 20px;
		}
		&:hover{
			background-color: black;
			color: white;
		}
	}
}
</style>
