<template>
	<section class="section is-paddingless gallery">
		<div class="custom-container">
			<div
				class="columns is-gapless touch-column"
				:class="[ revert ? 'revert' : '' ]"
			>
				<div
					class="column is-4 gallery__left-side"
					:class="[ revert ? 'orange' : '' ]"
				>
					<div class="gallery__left-side--padding">
						<div class="gallery__left-inner">
							<h2 v-html="data.title" class="is-size-4 custom-size"></h2>
							<div class="text is-size-7" v-html="data.content">

							</div>
							<a :href="data.button_href" class="custom-button" target="_blank">{{ data.button_value }}</a>
						</div>
					</div>
				</div>
				<div class="column is-8 gallery__relative">
					<gallery-slider :image="data.apartment[chosenImage]" />
					<div class="gallery__buttons">
						<a
							href="#"
							class="left"
							@click.prevent="prevSlide()"
						><img src="@/assets/las_icons-01.svg" /></a>
						<a
							href="#"
							class="right"
							@click.prevent="nextSlide()"
						><img src="@/assets/las_icons-02.svg" /></a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import GallerySlider from '../GallerySlider.vue'

export default{
	components: {
		GallerySlider
	},
	data() {
		return{
			chosenImage: 0,
			revert: false
		}
	},
	methods: {
		numberColor(index){
			if (this.chosenImage == index){ return 'colorize' }
		},
		changeSlide(index){
			this.chosenImage = index
		},
		prevSlide(){
			if (this.chosenImage - 1 < 0) {
				this.chosenImage = this.data.apartment.length
			}
			this.chosenImage--
		},
		nextSlide(){
			if (this.chosenImage+1 == this.data.apartment.length) {
				this.chosenImage = 0
			}else{
				this.chosenImage++
			}
		}
	},
	created() {
        if (this.data.revert[0]) {
        	this.revert = true
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.revert{
	flex-direction: row-reverse;
}
.gallery{
	&__left-inner{
		h2{
			font-family: 'Gilroy-bold';
			color: #000;
			line-height: 30px;
			text-transform: uppercase;
		}
		.text{
			margin-top: 20px;
			color: #000;
		}
		.custom-button{
			border: 1px solid black;
			width: calc(100% - 100px);
			text-align: center;
			color: black;
			text-transform: uppercase;
			display: block;
			padding: 12px 0;
			position: absolute;
			bottom: 40px;
			transition: .2s ease;
			font-family: 'Gilroy-bold';
			@include las-small-screen{
				font-size: .8rem;
				width: calc(100% - 60px);
				bottom: 30px;
			}
			&:hover{
				background-color: black;
				color: white;
			}
		}
	}
	&__left-side{
		background-color: $blue;
		min-height: 500px;
		position: relative;
		&--padding{
			padding: 50px;
			@include las-small-screen{
				padding: 30px;
			}
		}
	}
	&__relative{
		position: relative;
		min-height: 500px;
	}
	&__buttons{
		.left, .right{
			background-color: $yellow;
			width: 30px;
			height: 40px;
			z-index: 800;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
            z-index: 1;
		}
		.left{
			left: 0;
			img{
				margin-top: 4px;
			}
		}
		.right{
			right: 0;
			img{
				margin-top: 4px;
			}
		}
	}
}

	.custom-size{
		font-family: 'Gilroy-bold';
		@include las-small-screen{
			font-size: 1.6rem !important;
		}
		@include las-touch{
			font-size: 1.2rem !important;
		}
	}

.orange{
	background-color: $orange;
}
</style>
