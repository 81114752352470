<template>
	<section class="section is-paddingless map">
		<div class="custom-container">
			<div class="map__googlemap">
				<transition name="slide-fade">
					<div class="map__overlay" v-if="overlay.toggle">
						<img
							src="@/assets/X_ZAMKNIECIE.svg"
							@click="overlay.toggle = false"
						>
						<div class="columns map__overlay--margin">
							<div class="column is-6">
								<h2 class="is-size-3">{{ overlay.title }}</h2>
								<p class="map__border">{{ overlay.address }}</p>
								<hr>
								<p v-html="overlay.description"></p>
							</div>
						</div>
					</div>
  				</transition>
				<gmap-map
					:center="{lat: 51.119837, lng: 17.0454979}"
					:zoom="14"
					:options="mapStyle"
					class="map__iframe"
				>
					<GmapMarker
						:key="index"
						v-for="(m, index) in data.repeater"
						:icon="m.icon.url"
						:position="{lat: replace(m.lat), lng: replace(m.lng)}"
						@mouseover="openInfoWindowTemplate(m.lat, m.lng, m.title)"
						@mouseout="infoWindow.open = false"
						@click="openOverlay(m.title, m.address, m.description)"
					/>
					<gmap-info-window
					    :options="{maxWidth: 300}"
					    :position="infoWindow.position"
					    :opened="infoWindow.open"
					>
					    <div>
							<h2>{{ infoWindow.title }}</h2>
						</div>
					</gmap-info-window>
				</gmap-map>
			</div>
		</div>
	</section>
</template>

<script>

const mapStyle = [
	{
		"featureType": "all",
		"elementType": "geometry.fill",
		"stylers": [{"weight": "1.00"}]
	}, {
		"featureType": "all",
		"elementType": "geometry.stroke",
		"stylers": [{"color": "#9c9c9c"}]
	}, {
		"featureType": "all",
		"elementType": "labels.text",
		"stylers": [{"visibility": "on"}]
	}, {
		"featureType": "landscape",
		"elementType": "all",
		"stylers": [{"color": "#f2f2f2"}]
	}, {
		"featureType": "landscape",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#ffffff"}]
	}, {
		"featureType": "landscape.man_made",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#dddddd"}]
	},
	{"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]},
	{
		"featureType": "road",
		"elementType": "all",
		"stylers": [{"saturation": -100}, {"lightness": 45}]
	}, {
		"featureType": "road",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#eeeeee"}]
	}, {
		"featureType": "road",
		"elementType": "labels.text.fill",
		"stylers": [{"color": "#7b7b7b"}]
	}, {
		"featureType": "road",
		"elementType": "labels.text.stroke",
		"stylers": [{"color": "#ffffff"}]
	}, {
		"featureType": "road.highway",
		"elementType": "all",
		"stylers": [{"visibility": "simplified"}]
	}, {
		"featureType": "road.arterial",
		"elementType": "labels.icon",
		"stylers": [{"visibility": "all"}]
	}, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "on"}]}, {
		"featureType": "water",
		"elementType": "all",
		"stylers": [{"color": "#46bcec"}, {"visibility": "on"}]
	}, {
		"featureType": "water",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#c8d7d4"}]
	}, {
		"featureType": "water",
		"elementType": "labels.text.fill",
		"stylers": [{"color": "#070707"}]
	}, {"featureType": "water", "elementType": "labels.text.stroke", "stylers": [{"color": "#ffffff"}]}
]

export default{
	data() {
		return{
			mapStyle: {styles: mapStyle},
			infoWindow: {
	        	position: {lat: 0, lng: 0},
	        	open: false,
				title: ''
		  	},
			overlay: {
				toggle: false,
				title: '',
				description: '',
				address: ''
			}
		}
	},
	methods: {
		replace(p){
			return parseFloat(p.replace(',','.').replace(' ',''))
		},
		openInfoWindowTemplate (lat, lng, title) {
	        this.infoWindow.position = {lat: this.replace(lat)+0.0026, lng: this.replace(lng)}
	        this.infoWindow.open = true
			this.infoWindow.title = title
	    },
		openOverlay(title, address, description) {
			this.overlay.toggle = true
			this.overlay.title = title
			this.overlay.address = address
			this.overlay.description = description
		}

  	}
}

</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(-30px);
  opacity: 0;
}
.map{
	&__googlemap{
		width: 100%;
		height: 500px;
		position: relative;
	}
	&__iframe{
		width: 100%;
		height: 100%;
	}
	&__overlay{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $orange;
		z-index: 800;
		color: #000;
		&--margin{
			margin: 20px;
			h2{
				font-family: 'Gilroy-bold';
			}
			hr{
				background-color: #000;
				height: 1px;
			}
		}
		img{
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
		}
	}
}
</style>
